import React from 'react'
import '../styles/MenuItem.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearCart,
    removeItem,
    increase,
    decrease,
    calculateTotals
} from '../features/cart/cartSlice';
import {config} from '../config';

export const MenuItem = ({ item }) => {
    const dispatch = useDispatch();
    const { cartItems, total } = useSelector((state) => state.cart);
    console.log(cartItems)
    console.log(item)
    
    const handleDecrease = (item) => {
        dispatch(decrease(item))
        dispatch(calculateTotals())
    }

    const handleIncrease = (item) => {
        dispatch(increase(item))
        dispatch(calculateTotals())
    }
    return (
        <div className='productItem' >
            <div className='foodPhoto' style={{ backgroundImage: `url(${config.IMAGE_URL}/${item.image})` }}> </div>
            <h1> {item.name} </h1>
            <p> by {item.seller} </p>
            <h3> {item.price}$ </h3>

            <div className='addOrRemove'>
                <button onClick={()=> handleDecrease(item)} >-</button>
                <button disabled >{cartItems.filter(elem => elem['_id'] == item["_id"]).length}</button>
                <button  onClick={()=> handleIncrease(item)}>+</button>
            </div>
        </div>
    )
}
