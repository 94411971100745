import React from 'react'

export const Step = ({curstep, step, navbarTitle}) => {
    return (
        <div className='step' >
            <div className={`step-num ${step == curstep ? 'active' : ''}`} >
                {curstep}
            </div>
    
        <div className='step-info' >
            <p> STEP {curstep}</p>
            <h3 style={{whiteSpace:"nowrap"}}  >{navbarTitle} </h3>
    
        </div>
        </div>
      )
}
