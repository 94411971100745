import React, { useState, useEffect } from 'react'
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import '../styles/Orders.css';
import { useDispatch, useSelector } from 'react-redux';
export const Orders = () => {
  const axiosPrivate = useAxiosPrivate();
  const [orders, setOrders] = useState([])
  const { cartItems, total, deneme } = useSelector((state) => state.cart);
  const [activeOptions, setActiveOptions] = useState(new Set())
  console.log(orders)

  const toggleOptions = (concreteProduct_id) => {
    if (activeOptions.has(concreteProduct_id)) {
      setActiveOptions(prev => new Set([...prev].filter(elem => elem !== concreteProduct_id)))
    } else {
      setActiveOptions(prev => new Set(prev.add(concreteProduct_id)))
    }
  }

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getOrders = async () => {
      try {
        const response = await axiosPrivate.get(`/orders/getorders`, {
          signal: controller.signal
        });
        console.log(response.data)
        isMounted && setOrders(response?.data?.orders)


      } catch (error) {
        console.log(error)
      }
    }
    getOrders()

    return () => {
      isMounted = false;
      controller.abort();
    }
  }, [])

  return (
    <div className='ordersPage'>
      <div className='ordersContainer' >

        {orders.length == 0 && <h1 style={{ display: "flex", justifySelf: "center" }} >You don't have any previous orders.</h1>}
        {orders.length >=1  && <h1 style={{ display: "flex", justifySelf: "center" }} >Order History</h1>}
        {orders.map((order) => {
          return (
            <div style={{ border: "3px solid gray", padding: "16px", }}>
              <h4>Order Date: {order.createdAt.split("T")[0]}</h4>
              <h4>Time: {order.createdAt.split("T")[1].split("Z")[0]}</h4>
              <h1 style={{marginBottom:"0px"}}  >Products</h1>
              {order.cartItems.map((cartItem) => {
                console.log(cartItem.selectedToppings)
                return (
                  <div   >
                    <div style={{ display: "flex", alignItems: "center" }} onClick={() => toggleOptions(cartItem.concreteProduct_id)} >
                      <h2>{cartItem.name}</h2>
                      <span className={`fa fa-solid fa-chevron-${!activeOptions.has(cartItem.concreteProduct_id) ? 'down' : 'up'}`} />
                    </div>
                    <div className={`${!activeOptions.has(cartItem.concreteProduct_id) ? 'isOrderToppingsCollapsed' : 'isOrderToppingsCollapsed true'}`}  >
                    {cartItem.selectedToppings && Object.keys(cartItem.selectedToppings).length >=1 &&  Object.keys(cartItem.selectedToppings).filter(topping => cartItem["selectedToppings"][topping] == true).map(topping => {
                      return (
                          <div style={{ display: "flex", gap: "10px", alignItems: 'center', marginBottom: "5px" , padding:"10px"}}>
                            <input
                              id={cartItem["_id"]}
                              type="checkbox"

                              checked={true}
                              value={true}
                            />
                            <label htmlFor={cartItem.concreteProduct_id} style={{ color: 'black', fontSize: '15px', }}  >
                              {topping}
                            </label>
                          </div>
                      )
                    })}
                        </div>
                  </div>
                )
              })}
              <h5 style={{ fontSize: "1rem" }}>Total {order.total}$</h5>
            </div>
          )
        })}
      </div>
    </div>
  )
}
