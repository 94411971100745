import React, { useState, useEffect, useRef } from 'react'
import '../styles/CreateMenuStep3.css';

const toppingsExamples = [
    {
        id: 1,
        title: "Extra Cheese",
        price: '2$',
    },
    {
        id: 2,
        title: "Extra Olives",
        price: '1$',
    },
    {
        id: 3,
        title: "Extra Onions",
        price: '1$'
    },
]

export const CreateMenuStep3 = ({toppingChoices, setToopingChoices,
    topping, setTopping,
    price, setPrice,
    handleAdd}) => {

    return (
        <div  >

            {Object.keys(toppingChoices).length ==0  && <div style={{ outline: "red 3px dashed", padding: "5px", display: "flex", flexDirection: 'column' }} >
                <p><i>Examples to add toppings.</i></p>
                {toppingsExamples.map((todo) => (
                    <div key={todo.id} style={{ display: "flex", gap: "10px", alignItems: 'center' }} >
                        <input
                            type="checkbox"
                            disabled
                            checked="false"
                        />
                        <label style={{ color: 'black', fontSize: '15px', }}  >
                            {todo.title}
                        </label>
                        <p>{todo.price}</p>
                    </div>
                ))}
            </div>}


            {Object.keys(toppingChoices).length >= 1  && <div style={{  padding: "5px", display: "flex", flexDirection: 'column' }} >
                <p><i>Added toppings options and prices </i></p>
                {Object.keys(toppingChoices).map((topping) => (
                    <div  style={{ display: "flex", gap: "5px", alignItems: 'center' , lineHeight:'1px'}} >
                        <input
                            type="checkbox"
                            
                            checked="false"
                        />
                        <label style={{ color: 'black', fontSize: '15px', }}  >
                            {topping}
                        </label>
                        <p>{toppingChoices[topping]}</p>
                    </div>
                ))}
            </div>}
            
            <div  >
                <br />
                <input
                    type='text'
                    name='topping'

                    placeholder='Topping Name'
                    value={topping}
                    onChange={(e) => setTopping(e.target.value)}
                />
                <input
                    style={{ fontSize: '20px' }}
                    type='number'
                    name='price'
                    placeholder='Price'
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                />
                <button style={{ padding: '8px 5px', marginLeft: '5px', borderRadius: '20px' }} onClick={handleAdd} >Add</button>
            </div>

        </div>
    )
}
