
// DEV
// export const config = {
//     IMAGE_URL: "http://localhost:3500/uploads",
//     API_URL: "http://localhost:3500/api/v1"
// }

// Prod
export const config = {
    IMAGE_URL:'https://gokhanerturk.net/uploads',
    API_URL:'https://gokhanerturk.net/api/v1'
}