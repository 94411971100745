import React, { useState, useEffect ,useRef} from 'react';
import '../styles/CreateMenu.css';
import * as Components from '../components';
import axios from 'axios';
import { data } from '../data';
import {foodTypes} from '../foodTypes';
import useAxiosPrivate from "../hooks/useAxiosPrivate";



export const CreateMenu = () => {
    const axiosPrivate = useAxiosPrivate();
    const [step, setStep] = useState(1);

    // step 1
    const [inputs, setInputs] = useState({});
    const [menuNameError, setMenuNameError] = useState('')
    const [menuPriceError, setMenuPriceError] = useState('')
    const [menuImageError, setMenuImageError] = useState('')
    const [file, setFile] = useState();
    const ref = useRef(null)

    // step 2
    const [selectedFoodType,setSelectedFoodType] = useState('Doner')

    // step 3
    const [toppingChoices, setToopingChoices] = useState({})
    const [topping, setTopping] = useState('')
    const [price, setPrice] = useState('')

    
    const handleAdd = () => {
        if (!topping || !price ){
            return 
        }
        
        setToopingChoices(prev => ({ ...prev, [topping]: `${price}$` }))
        setTopping('')
        setPrice('')
    }

    console.log(selectedFoodType)
    console.log(JSON.stringify(toppingChoices))
    const confirmMenuAdding= async() => {
        const { menuName, menuPrice, menuImage } = inputs
        const formData = new FormData();
               formData.append('menuName', menuName)
               formData.append('menuPrice', menuPrice)
               formData.append('menuImage', menuImage)
               formData.append('selectedFoodType',selectedFoodType)
               formData.append('toppingChoices',JSON.stringify(toppingChoices))
         try {
                   const sentProduct = await axiosPrivate.post('/products/uploads', formData, {
                       headers: {
                           'Content-Type': 'multipart/form-data'
                       }
                   })
                   // clean-ups
                   setInputs({})   // add multistep
                   setToopingChoices({})
                   setTopping('')
                   setPrice('')
                   ref.current && (ref.current.value = '');
               } catch (error) {
                   console.log(error)
               }
    }

    const handleNext = (e) => {
        if (step === 1) {
            const { menuName, menuPrice, menuImage } = inputs
            if (!menuName || !menuPrice, !menuImage) {
                // setMenuNameError('Please set a name for the menu')
                // Display an error message or perform other actions for failed validation
                alert('Please fill in all fields.')
                return;
            }
        }
        if (step === 2) {
              if (!selectedFoodType) {
                // Display an error message or perform other actions for failed validation
                alert("Please select the food type.");
                return; // Prevent moving to the next step
              }
        }
        // step 2 ??? 
        // step 3 ??? 

        if (step ===4){
            confirmMenuAdding()
        }
        e.preventDefault();
        if (step < 5) setStep((s) => s + 1);
    }

    const handlePrev = (e) => {
        e.preventDefault();
        if (step > 1) setStep((s) => s - 1);
    }
    return (
        <div className='createMenuPage' >
            <div className='createMenuContainer' >
                <div className='nav-menuContainer' >
                    {
                        data.map(item => (
                            <Components.Step curstep={item.step} step={step} navbarTitle={item.navbarTitle} key={item.step} />
                        ))
                    }
                </div>
                <div className='main-menuContainer' >
                    {
                        data.map(item => item.step == step && (
                            <div className='header-menuContainer' >
                                <h1 style={{ color: 'black' }} >{item.title}</h1>
                                <h3>{item.info}</h3>
                            </div>
                        ))
                    }
                    <div>
                        {
                            step === 1 && <Components.CreateMenuStep1
                                inputs={inputs}
                                setInputs={setInputs}
                                menuNameError={menuNameError}
                                setMenuNameError={setMenuNameError}
                                menuPriceError={menuPriceError}
                                setMenuPriceError={setMenuPriceError}
                                menuImageError={menuImageError}
                                setMenuImageError={setMenuImageError}
                                file={file}
                                setFile={setFile}
                                ref= {ref}
                            />
                        }
                        {
                            step === 2 && <Components.CreateMenuStep2
                                selectedFoodType={selectedFoodType}
                                setSelectedFoodType={setSelectedFoodType}
                                foodTypes={foodTypes}
                            />
                        }
                        {
                            step === 3 && <Components.CreateMenuStep3
                            toppingChoices={toppingChoices}
                            setToopingChoices={setToopingChoices}
                            topping={topping}
                            setTopping={setTopping}
                            price={price}
                            setPrice={setPrice}
                            handleAdd={handleAdd}
                            />
                        }
                        {
                            step === 4 && <Components.CreateMenuStep4 
                                inputs={inputs}
                                file={file}
                                selectedFoodType={selectedFoodType}
                                toppingChoices={toppingChoices}
                            />
                        }
                        {
                            step ===5 && <Components.ThankYou
                            />
                        }
                    </div>
                    <div className='buttons' >
                        {(step > 1 && step < 5) && <button type='submit' className='btn-secondary' onClick={handlePrev} > Go back</button>}
                        {(step < 4) && <button type='submit' className='btn-primary' onClick={handleNext} > Next Step</button>}
                        {(step === 4) && <button type='submit' className='btn-confirm' onClick={handleNext} > Confirm</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}
