import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../features/auth/authSlice';
import '../styles/SignUp.css';
import { Link } from 'react-router-dom';
import BannerImage from "../assets/fastfood2.jpg";

import { useNavigate, useLocation } from "react-router-dom";
import { clearRegisterMessages } from '../features/auth/authSlice';
import * as Components from '../components';

// center icon on top of an elemnets border
// https://stackoverflow.com/questions/29260771/centering-an-icon-on-top-of-an-elements-border
export const SignUp = () => {
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({});
    const [nameSpan, setNameSpan] = useState('');
    const [nameTick, setNameTick] = useState(false);
    const [emailSpan, setEmailSpan] = useState('');
    const [emailTick, setEmailTick] = useState(false);
    const [passwordSpan, setPasswordSpan] = useState('');
    const [passwordTick, setPasswordTick] = useState(false);
    const { registerSuccessfulMsg, isLoading, errorMsg } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    console.log({ registerSuccessfulMsg, isLoading, errorMsg })
    const clearSignUpMessages = () => {
        if (registerSuccessfulMsg){
            dispatch(clearRegisterMessages());
            navigate('/login')
        } else if (errorMsg) {
            dispatch(clearRegisterMessages());
            setNameTick(false)
            setEmailTick(false)
            setPasswordTick(false)
        }   
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value
        setInputs((values) => ({ ...values, [name]: value }))
    }


    const formValidation = () => {
        var { name, email, password } = inputs;

        // validate the existance 
        if (!name) {
            setNameSpan(' ** Please fill the Name field.')
            setNameTick(false)
            return false
        } else {
            setNameSpan('')
        }

        // validate name length
        if (name.length < 3 || name.length > 50) {
            setNameSpan(' ** Username length must be between 3 and 20')
            setNameTick(false)
            return false
        } else {
            setNameTick(true)
            setNameSpan('')
        }

        // validate email existance
        if (!email) {
            setEmailSpan(' ** Please fill the email  field')
            setEmailTick(false)
            return false;
        } else {
            setEmailSpan('')
        }

        //validate email
        if (
            email.charAt(email.length - 4) != '.' &&
            email.charAt(email.length - 3) != '.'
        ) {
            setEmailSpan(' ** Invalid Email')
            setEmailTick(false)
            return false
        } else {
            setEmailTick(true)
            setEmailSpan('')
        }

        // check password existance
        if (!password) {
            setPasswordSpan('** Please fill the password field')
            return false
        } else {
            setPasswordSpan('')
        }

        // validate password length
        if (password.length <= 5 || password.length > 20) {
            setPasswordSpan(' ** Passwords length must be between 5 and 20')
            return false
        } else {
            setPasswordTick(true)
            setPasswordSpan('')
        }

        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // validate the form, then dispatch
        if (formValidation()) {
            dispatch(register(inputs))
        }
    }

    return (
        <div className='container' style={{ backgroundImage: `url(${BannerImage})` }} >
            <br />
            {registerSuccessfulMsg == '' && errorMsg == '' ?
                (<div className='col-lg-5 m-auto d-block' >
                    <form onSubmit={handleSubmit} className='bg-light' >
                        <div className='form-group' >
                            <label for='name'>Name </label>
                            <span className={`material-symbols-outlined ${nameTick ? 'check' : 'checkHide'}`} >check</span>
                            <input type='text' name='name' className='form-control' onChange={handleChange} />
                            <span id='Name' className='text-danger'  > {nameSpan} </span>
                        </div>
                        <div className='form-group' >
                            <label for='email'>Email</label>
                            <span className={`material-symbols-outlined ${emailTick ? 'check' : 'checkHide'}`} >check</span>
                            <input type='email' name='email' className='form-control' onChange={handleChange} />
                            <span id='emailid' className='text-danger'> {emailSpan} </span>
                        </div>
                        <div className='form-group' >
                            <label for='password'>Password</label>
                            <span className={`material-symbols-outlined ${passwordTick ? 'check' : 'checkHide'}`} >check</span>
                            <input type='password' name='password' className='form-control' onChange={handleChange} />
                            <span id='password' className='text-danger'> {passwordSpan} </span>
                        </div>
                        <div className='loginbuttons'>
                            <button type='submit' className='btn btn-primary' >SignUp</button>
                            <a className='text' > Already have an account? </a>
                            <Link to="/login">
                                <button className='btn btn-signup' > Login </button>
                            </Link>
                        </div>
                    </form>
                </div>) :
                    registerSuccessfulMsg ? 
                <Components.Modal closeFunction= {clearSignUpMessages} modelType='registerSuccessModal' /> :
                <Components.Modal closeFunction= {clearSignUpMessages} modelType='registerErrorModal' /> 

            }
        </div>
    )
}
