import React, { useState, useEffect, Component } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    clearCart,
    removeItem,
    increase,
    decrease,
    calculateTotals,
    toggleToppings
} from '../features/cart/cartSlice';
import * as Components from '../components';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
  
import '../styles/Cart.css';
import { useNavigate, useLocation } from "react-router-dom";
import {config} from '../config';
export const Cart = () => {
    // https://stackoverflow.com/questions/58806883/how-to-use-set-with-reacts-usestate
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axiosPrivate = useAxiosPrivate();
    const [activeOptions, setActiveOptions] = useState(new Set())
    const { cartItems, total, deneme } = useSelector((state) => state.cart);
    const [isModalOpen, setIsModalOpen] = useState(false)
    console.log(cartItems)
    console.log(deneme)

    console.log(activeOptions)
    const toggleOptions = (concreteProduct_id) => {
        if (activeOptions.has(concreteProduct_id)) {
            setActiveOptions(prev => new Set([...prev].filter(elem => elem !== concreteProduct_id)))
        } else {
            setActiveOptions(prev => new Set(prev.add(concreteProduct_id)))
        }
    }
    function modalClose (){
        setIsModalOpen(false)
        navigate('/orders')
    }
    const handleChange = ({ id, choice }) => {
        
        console.log(id, choice)
        dispatch(toggleToppings({ concreteProduct_id: id, choice }))
        dispatch(calculateTotals())
    }

    const createOrder = async () => {
        const response = await axiosPrivate.post("/orders/createorder", {cartItems,total})
        if (response.status == 200){
            dispatch(clearCart())
            dispatch(calculateTotals())
        }
    }
    const handleConfirm = () => {
        setIsModalOpen(true)
        createOrder()
    }
    return (
        <div className='cartPage' >
            
            {isModalOpen &&  <Components.Modal closeFunction={modalClose} modelType='orderConfirmModal' />}
            {!isModalOpen && <div className='cartContainer' >
               {total != 0 &&  <h1>Select the toppings of each product and click confirm.</h1>}
                {cartItems.map(item => {
                    return (
                        <div className='order' >

                            <div>
                                <div className='orderPhoto' style={{ backgroundImage: `url(${config.IMAGE_URL}/${item.image})` }}> </div>
                                <h1> {item.name} </h1>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }} >
                                <div style={{ display: "flex", alignItems: "center" }} onClick={() => toggleOptions(item.concreteProduct_id)} >
                                    <h3>Optional Toppings</h3>
                                    <span className={`fa fa-solid fa-chevron-${!activeOptions.has(item.concreteProduct_id) ? 'down' : 'up'}`} />
                                </div>

                                <div className={`${!activeOptions.has(item.concreteProduct_id) ? 'isCollapsed' : 'isCollapsed true'}`} >
                                    {Object.keys(item.toppingChoices).map(choice => {
                                        console.log(item.concreteProduct_id, item["selectedToppings"])
                                        return (
                                            <div style={{ display: "flex", gap: "10px", alignItems: 'center', marginBottom: "5px" , padding:"5px" }}  >
                                                <input
                                                    id={item.concreteProduct_id}
                                                    type="checkbox"
                                                    onChange={(e) => handleChange({ id: item.concreteProduct_id, choice })}
                                                    checked={item["selectedToppings"][choice] || false}
                                                    value={item["selectedToppings"][choice] || false}
                                                />
                                                <label htmlFor={item.concreteProduct_id} style={{ color: 'black', fontSize: '15px', }}  >

                                                    {choice}
                                                </label>
                                                <p>{item.toppingChoices[choice]}</p>

                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <h3 className='item-price' > {item.price}$ </h3>
                        </div>
                    )

                })}
                {total == 0 &&  (
                    <>
                    
                    <h4 style={{justifySelf:"center"}} >Cart is Empty</h4>
                    </>
            )}
                <h1 style={{ justifySelf: "center" }} >Total Price: {total} $</h1>
                <button  onClick={() =>handleConfirm() } className='ConfirmButton' >Confirm Order </button>

            </div>}

        </div>
    )
}
