import React from 'react'
import Check from '../assets/Check.png'
import warning from '../assets/warning.png'
import orderConfirm from '../assets/orderConfirm.png'
import '../styles/Modal.css';
import { useSelector } from 'react-redux';

export const Modal = ({ closeFunction, modelType }) => {
    const { errorMsg, loginErrorMsg } = useSelector((state) => state.auth);

    const modelTypes = {
        registerSuccessModal: () => {
            return (
                <>
                    <img src={Check} />
                    <h2>Awesome!</h2>
                    <p>
                        Account created successfully. <br /> Check your mail to verify your account.
                    </p>
                </>
            )
        },
        registerErrorModal: () => {
            return (
                <>
                    <img src={warning} />
                    <h2>Error !</h2>
                    <p>
                        {errorMsg}
                    </p>
                </>
            )
        },
        loginErrorModal: () => {
            return (
                <>
                    <img src={warning} />
                    <h2>Error !</h2>
                    <p>
                        {loginErrorMsg}
                    </p>
                </>
            )
        },
        orderConfirmModal: () => {
            return (
                <>
                    <img src={orderConfirm} />
                    <h2>Order confirmed ! </h2>
                    <p>
                        Unfortunately this is a prototype application so you won't be getting any food in 30 minutes. Wanna chat instead ?
                    </p>
                </>
            )
        }
    }


    return (
        <div className='modal' >
            <div className="popup">
                {modelTypes[modelType]()}
                <button onClick={closeFunction} className='modalButton' > OK</button>
            </div>
        </div>
    )
}
