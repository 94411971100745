import React from 'react'
import hamburger from '../assets/hamburger.svg'
import '../styles/LoaderHamburger.css';
export const LoaderHamburger = () => {
    return (
        <div className='LoaderBody'>
            <img src={hamburger} alt="Hamburger Loader" className='PizzaSlice' />
            <h2 className='Text'>Loading</h2>
        </div>
    )
}
