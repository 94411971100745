import React from 'react'
import { Routes, Route } from 'react-router-dom';
import * as Components from './components';
import * as Pages from './pages';
import './styles/App.css';

const ROLES = {
    'User': 2001,
    'Editor': 1984,
    'Admin': 5150
}

const LazyHome = React.lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, 1650));
    return import('./pages/Home')
})

const LazyMenu = React.lazy(async () => {
    await new Promise(resolve => setTimeout(resolve, 1650));
    return import('./pages/Menu')
})
const App = () => {
    return (
        <div className='App'>
            <Components.Navbar />
            <Routes>
                <Route element={<Components.PersistLogin />}>
                    <Route path='/'
                        element={
                            <React.Suspense fallback={<Components.LoaderHamburger />} >
                                <LazyHome />
                            </React.Suspense>
                        }
                    />
                    <Route path='/login' element={<Pages.Login />} />
                    <Route path='/signup' element={<Pages.SignUp />} />
                    <Route path='/uploadmenu' element={<Pages.UploadMenu />} />

                    <Route element={<Components.RequireAuth allowedRoles={[ROLES.User]} />}>
                        <Route path='/menu'
                            element={
                                <React.Suspense fallback={<Components.LoaderHamburger />} >
                                    <LazyMenu />
                                </React.Suspense>
                            }
                        />
                        <Route path='/cart' element={<Pages.Cart />} />
                        <Route path='/create-menu' element={<Pages.CreateMenu />} />
                        <Route path='/orders' element={<Pages.Orders />} />
                    </Route>


                </Route>

            </Routes>
            <Components.Footer />

        </div>

    )
}

export default App