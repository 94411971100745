import React, { useState, useEffect, useRef } from 'react'
import '../styles/CreateMenuStep4.css';

export const CreateMenuStep4 = ({ inputs,
    file,
    selectedFoodType,
    toppingChoices }) => {

    const { menuName, menuPrice } = inputs
    return (
        <div style={{display:"flex"}}>

            <div className="menuItem" style={{display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                <div style={{ backgroundImage: `url(${file})` }}> </div>
                <h1> {menuName} </h1>
                <p> {menuPrice}$ </p>
                </div>

    
            <div>
                <h3>Optional Toppings</h3>
            {
                Object.keys(toppingChoices).length >=1 && 
                <ul>
                    {Object.keys(toppingChoices).map(key => (<li>{key} for {toppingChoices[key]} </li>))}
                </ul>
            }
            </div>

        </div>
    )
}
