import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import axios from '../../api/axios';

const registerUrl = '/auth/register';
const loginUrl  = '/auth/login';

const initialState = {
    registerSuccessfulMsg:'',
    isLoading:false,
    errorMsg:'',
    roles:[],
    accessToken:'',
    loggedIn:false,
    loginSuccess:false,
    loginErrorMsg:'',
    persist:JSON.parse(localStorage.getItem("persist")) || false
}

export const register = createAsyncThunk(
    'auth/register',
    async ({name,email,password}, {rejectWithValue}) => {
        try {
            const {data} = await axios.post(registerUrl,  {username:name,email,password})
            return data
        } catch (error) {
            // console.log(error.response)
            return rejectWithValue(error.response?.data?.msg)
        }
    }
)


export const login = createAsyncThunk(
    'auth/login',
    async ({email,password}, {rejectWithValue}) => {
        try {
            const {data} = await axios.post(loginUrl,  {email,password},          {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: true
            })
            localStorage.setItem("persist", true);
            return data
        } catch (error) {
            return rejectWithValue(error.response?.data?.msg)
        }
    }
)

const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers: {
        clearRegisterMessages: (state) => {
            state.registerSuccessfulMsg = '';
            state.errorMsg = '';
        },
        clearLoginMessages: (state) => {
            state.loginErrorMsg = '';
        },
        refreshAccessToken: (state, action) => {
            console.log("helloooo")
            console.log(action.payload)
            return {
              ...state,
              loggedIn:true,
              ...action.payload
            }
        },
        logoutStateCleanup: (state) => {
            state.accessToken = ''
            state.roles = []
            state.loggedIn= false
            state.loginSuccess = false
            state.loginErrorMsg = ''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(register.pending, (state) => {
            state.isLoading = true;
            state.errorMsg = '';
        });
        builder.addCase(register.fulfilled, (state,action) => {
            state.isLoading = false;
            state.errorMsg = '';
            state.registerSuccessfulMsg = action.payload.msg
        });
        builder.addCase(register.rejected, (state,action) => {
            console.log(action)
            state.isLoading = false;
            state.errorMsg = 
                action.payload || 'No Server Response. Try again later'
        });
        builder.addCase(login.fulfilled, (state,action)=> {
            console.log(action.payload)
            state.accessToken = action.payload.accessToken
            state.roles = action.payload.roles
            state.loginSuccess = true
            state.loginErrorMsg = ''
            state.loggedIn= true
        })
        builder.addCase(login.rejected, (state,action)=> {
            console.log(action.payload)
            state.loginErrorMsg = action.payload
        })
    }
})

export const {clearRegisterMessages, clearLoginMessages ,refreshAccessToken,logoutStateCleanup} = authSlice.actions;
export default authSlice.reducer;