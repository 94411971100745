import React from 'react'
import '../styles/FoodTypeCards.css';
export const FoodTypeCards = ({ selectedFoodType, setSelectedFoodType, foodType }) => {
    function handlePlan(PlanName) {
        setSelectedFoodType(PlanName === selectedFoodType ? '' : PlanName);
    }
    return (
        <div className = {`card ${selectedFoodType === foodType.name ? 'activeCard' : ''}`} onClick = {() => handlePlan(foodType.name)}>
        <img src={foodType.img} alt="" />
        <h3>{foodType.name}</h3>    
      </div >
    )
}
