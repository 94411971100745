import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";


export const RequireAuth = ({ allowedRoles }) => {
    const {accessToken, roles } = useSelector((state) => state.auth);
    const location = useLocation();
    console.log("aaa require auth")
    return (
        roles?.find(role => allowedRoles?.includes(role))
            ? <Outlet />
            : accessToken //changed from user to accessToken to persist login after refresh
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

