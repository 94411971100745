import React, { useState, useEffect, useRef } from 'react'
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import '../styles/UploadMenu.css';

export const UploadMenu = () => {
    const axiosPrivate = useAxiosPrivate();
    const [inputs, setInputs] = useState({});
    const [menuNameError, setMenuNameError] = useState('')
    const [menuPriceError, setMenuPriceError] = useState('')
    const [menuImageError, setMenuImageError] = useState('')
    const ref = useRef(null)
    console.log(menuNameError)
    const handleChange = (event) => {
        console.log(event.target)
        const name = event.target.name;
        const value = event.target.value;
        if (name == 'menuImage') {
            setInputs(values => ({ ...values, [name]: event.target.files[0] }))
        } else {

            setInputs(values => ({ ...values, [name]: value }))
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const { menuName, menuPrice, menuImage } = inputs
        if (!menuName) {
            setMenuNameError('Please set a name for the menu')
            return
        }
        if (!menuPrice) {
            setMenuPriceError('Please set a price for the menu')
            return
        }
        if (!menuImage) {
            setMenuImageError('Please select a photo for the menu')
            return
        }
        const formData = new FormData();
        formData.append('menuName', menuName)
        formData.append('menuPrice', menuPrice)
        formData.append('menuImage', menuImage)

        try {
            const sentProduct = await axiosPrivate.post('/products/uploads', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setInputs({})   // add multistep
            ref.current && (ref.current.value = '');
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit} className='form file-form'>
                <h4>Upload Menu </h4>
                <div className='form-row' >
                    <label for='name' className='form-label' >Menu Name</label>
                    <input type='text' name='menuName' id='name' className='form-input' value={inputs['menuName'] || ''} required onChange={handleChange} />
                    {menuNameError && (<span id='Name' className='text-danger'  > {menuNameError} </span>)}
                </div>
                <div className='form-row' >
                    <label for='price' className='form-label' >Price</label>
                    <input type='number' name='menuPrice' id='price' className='form-input' value={inputs['menuPrice'] || ''} required onChange={handleChange} />
                </div>
                <div className='form-row' >
                    <label for='image' className='form-label' >Image</label>
                    <input type='file' name='menuImage' id='image' accept='image/*' required onChange={handleChange} ref={ref} ></input>
                </div>
                <button disabled={!inputs["menuName"] || !inputs["menuPrice"] || !inputs["menuImage"] ? true : false} type='submit' className='uploadButton btn-block'  >Add Menu</button>
            </form>
        </>
    )
}
