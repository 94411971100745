import axios from '../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { logoutStateCleanup} from '../features/auth/authSlice';

const useLogout = () => {
    const dispatch = useDispatch();
    const logout = async() => {
        dispatch(logoutStateCleanup())
        try {
            const response = await axios('/auth/logout', {
                withCredentials:true
            });
        } catch (error) {
            console.error(error)
        }
    }

    return logout;
}

export default useLogout