import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    cartItems: [],
    total: 0,
    deneme:{}
}


var mongoObjectId = function () {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function () {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        clearCart: (state) => {
            state.cartItems = [];
        },
        removeItem: (state, { payload }) => {
            console.log("first")
        },
        toggleToppings: (state, {payload}) => {
            console.log(payload)
            const {concreteProduct_id, choice} = payload
            console.log(concreteProduct_id, choice)
          const item =  state.cartItems.find(item => item["concreteProduct_id"] == concreteProduct_id)
          item['selectedToppings'][choice] =  !item['selectedToppings'][choice] ? true:false
          state["deneme"][concreteProduct_id]=true
          console.log(item)
        },
        increase: (state, { payload }) => {
            state.cartItems = [...state.cartItems,
            {
                ...payload,
                concreteProduct_id: mongoObjectId(),
                date_added_to_cart: new Date().valueOf(),
                selectedToppings:{}
            }]
            console.log(state.cartItems)
        },
        decrease: (state, { payload }) => {
            if (state.cartItems.find(item => item["_id"] == payload["_id"])) {
                var IdOfLastItemAddedWithSpecificId = state.cartItems.filter(item => item["_id"] == payload["_id"])
                    .sort((a, b) => new Date(b["date_added_to_cart"]) - new Date(a["date_added_to_cart"]))[0]["concreteProduct_id"]
                state.cartItems = state.cartItems.filter((item) => item.concreteProduct_id !== IdOfLastItemAddedWithSpecificId);
            }
        },
        calculateTotals: (state) => {
            let total = 0
            state.cartItems.forEach((item) => {
                total += item.price
                let selectedToppings = Object.keys(item.selectedToppings).filter(topping => item["selectedToppings"][topping] == true)
                let totalTopping =0
                selectedToppings.forEach((topping) => {
                    console.log(Number(item.toppingChoices[topping].split('$')[0]))
                    totalTopping += Number(item.toppingChoices[topping].split('$')[0])
                })
                total = total + totalTopping
                console.log(total)
            })
            state.total = total
        }
    }
})

export const { clearCart,
    removeItem,
    increase,
    decrease,
    calculateTotals ,
    toggleToppings
} = cartSlice.actions;
export default cartSlice.reducer;