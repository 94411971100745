import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import '../styles/CreateMenuStep1.css';


export const CreateMenuStep1 = ({inputs,
    setInputs,
    menuNameError,
    setMenuNameError,
    menuPriceError,
    setMenuPriceError,
    menuImageError,
    setMenuImageError,
    file,
    setFile,
    ref
}) => {

    const handleChange = (event) => {
        console.log(event.target)
        const name = event.target.name;
        const value = event.target.value;
        if (name == 'menuImage') {
            setFile(URL.createObjectURL(event.target.files[0]));
            setInputs(values => ({ ...values, [name]: event.target.files[0] }))
        } else {

            setInputs(values => ({ ...values, [name]: value }))
        }
    }
    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const { menuName, menuPrice, menuImage } = inputs
    //     if (!menuName) {
    //         setMenuNameError('Please set a name for the menu')
    //         return
    //     }
    //     if (!menuPrice) {
    //         setMenuPriceError('Please set a price for the menu')
    //         return
    //     }
    //     if (!menuImage) {
    //         setMenuImageError('Please select a photo for the menu')
    //         return
    //     }
    //     const formData = new FormData();
    //     formData.append('menuName', menuName)
    //     formData.append('menuPrice', menuPrice)
    //     formData.append('menuImage', menuImage)

    //     try {
    //         const sentProduct = await axios1.post('/products/uploads', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data'
    //             }
    //         })
    //         setInputs({})   // add multistep
    //         ref.current && (ref.current.value = '');
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    return (
        <>
            <form  className=''>
                <div className='form-row' >
                    <label for='name' className='form-label' >Menu Name</label>
                    <input type='text' name='menuName' id='name' className='form-input' value={inputs['menuName'] || ''} required onChange={handleChange} />
                    {menuNameError && (<span id='Name' className='text-danger'  > {menuNameError} </span>)}
                </div>
                <div className='form-row' >
                    <label for='price' className='form-label' >Price</label>
                    <input type='number' name='menuPrice' id='price' className='form-input' value={inputs['menuPrice'] || ''} required onChange={handleChange} />
                </div>
                <div className='form-row' >
                    <label for='image' className='form-label' >{!file ? "Select Image" : 'Change Image'} </label>
                    <input  type='file'  name='menuImage' id='image' accept='image/*'   required onChange={handleChange} ref={ref} ></input>
                </div>
                <img style={{width:'80px'}} src={file} />
                {/* <button disabled={!inputs["menuName"] || !inputs["menuPrice"] || !inputs["menuImage"] ? true : false} type='submit' className='uploadButton btn-block'  >Add Menu</button> */}
            </form>
        </>
    )
}
