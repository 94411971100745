import React, { useState, useEffect, useRef } from 'react'
import '../styles/CreateMenuStep2.css';
import { FoodTypeCards } from './FoodTypeCards';

export const CreateMenuStep2 = ({
    selectedFoodType,
    setSelectedFoodType,
    foodTypes
}) => {
    return (
        <div className='foodType-cards' >
            {
            foodTypes.map(foodType => <FoodTypeCards 
                selectedFoodType={selectedFoodType}
                setSelectedFoodType={setSelectedFoodType}
                foodType={foodType}
            />)
            }
        </div>
    )
}
