import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    clearCart,
    removeItem,
    increase,
    decrease,
    calculateTotals
} from '../features/cart/cartSlice';
import '../styles/CartSide.css';
export const CartSide = () => {
    const dispatch = useDispatch();
    const { cartItems, total } = useSelector((state) => state.cart);
    const [uniqueCartItemIds, setUniqueCartItemIds] = useState([]);

    console.log(uniqueCartItemIds)
    // https://stackoverflow.com/questions/15125920/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
    useEffect(() => {
        var uniqueIds = [...new Set(cartItems.map(item => item["_id"]))]
        var uniqueItemSamples = uniqueIds.map(id => cartItems.find(cartItem => cartItem["_id"] == id))
        setUniqueCartItemIds(uniqueItemSamples)
    }, [cartItems])

    const handleClearCart = () => {
        dispatch(clearCart())
        dispatch(calculateTotals())
    }

    const handleDecrease = (item) => {
        dispatch(decrease(item))
        dispatch(calculateTotals())
    }

    const handleIncrease = (item) => {
        dispatch(increase(item))
        dispatch(calculateTotals())
    }
    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between", borderBottom: '1px solid #000', padding: "5px", paddingBottom: "10px", paddingBottom: '5px', margin: '5px', lineHeight: "2px" }} >
                <div style={{ display: "flex", alignItems: "center" }} >
                    <span style={{padding:"0" }} className="fa fa-solid fa-shopping-cart"></span>
                    <h1>Cart</h1>
                </div>

                <button className='clearButton' onClick={() => handleClearCart()} >Clear Cart</button>
            </div>
            <div style={{ height: "380px", overflowY: "scroll", overflowX: 'hidden' }}>
                <div style={{ height: "auto", display: "grid", gridTemplateRows: "auto" }} >
                    {
                        uniqueCartItemIds.map((item) => {
                            return (
                                <div style={{ borderBottom: '1px solid #000', paddingBottom: '5px', lineHeight: "1px", margin: "5px" }} >
                                    <h4>{item.name}</h4>
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "5px" }} >
                                        <h4 style={{ color: '#606673' }}> {item.price}$ </h4>
                                        <div className='cartSideaddOrRemove' >
                                            <button onClick={() => handleDecrease(item)} >-</button>
                                            <p disabled >{cartItems.filter(elem => elem['_id'] == item["_id"]).length}</p>
                                            <button onClick={() => handleIncrease(item)}>+</button>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>
                <div style={{
                    position: "absolute",
                    bottom: "-20px",
                    left: "0",
                    width: "100%",
                    background: "#dddddd"
                }} >
                    <strong style={{ marginTop: "8px", marginBottom: "12px", marginLeft: '0.5rem', fontSize: "1rem" }} >Total: {total} $ </strong>

                    <Link to='/cart' >
                        <button style={{ width: "100%", padding: "8px 0", backgroundColor: '#724cf9', color: "#fff", border: "none", outline: "none" }} >GO TO CART</button>
                    </Link>
                </div>
            </div>
        </>
    )
}
