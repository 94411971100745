import React from 'react'
import '../styles/Footer.css';

// https://www.javatpoint.com/how-to-add-social-media-icons-in-html

export const Footer = () => {
    return (
        <div className='footer' >
            <div className='socialMedia' >
                <a href="#" class="fa fa-facebook"> </a>
                <a href="#" class="fa fa-twitter"> </a>
                <a href="#" class="fa fa-linkedin"> </a>
                <a href="#" class="fa fa-instagram"> </a>
            </div>
            <p> &copy; 1958 turkishfood.com</p>
        </div>
    )
}
