import React, { useState } from 'react';
import Logo from '../assets/pizzaLogo.png';
import hamburger from '../assets/hamburger.svg'
import { NavLink, useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';
import useLogout from '../hooks/useLogout';
import { useSelector } from 'react-redux';
import power from '../assets/power.svg'

export const Navbar = () => {
    const { loggedIn } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [openLinks, setOpenLinks] = useState(false);

    const logout = useLogout();
    console.log(loggedIn)
    const signOut = async () => {
        await logout();
        navigate('/login');
    }

    const toggleNavbar = () => {
        setOpenLinks(!openLinks)
    }

    const navLinkStyles = ({ isActive }) => {
        return {
            fontWeight: isActive ? 'bold' : 'normal',
            textDecoration: isActive ? 'none' : 'underline'
        }
    }

    return (
        <div className='navbar' >
            <div className='leftSide' id={openLinks ? 'open' : 'close'} >
                <img src={hamburger} />
                <div className='hiddenlinks' >
                    <NavLink to='/' style={navLinkStyles}> Home</NavLink>
                    <NavLink to='/menu' style={navLinkStyles}> Menu</NavLink>
                    <NavLink to='/create-menu' style={navLinkStyles}> Create-Menu</NavLink>
                    <NavLink to='/orders' style={navLinkStyles}> Orders</NavLink>
                    {!loggedIn &&
                        (<NavLink to='/Login' style={navLinkStyles}> Login</NavLink>) 
                        
                    }
                </div>
            </div>
            <div className='rightSide' >
                <NavLink to='/' style={navLinkStyles}> Home</NavLink>
                <NavLink to='/menu' style={navLinkStyles}> Menu</NavLink>
                <NavLink to='/create-menu' style={navLinkStyles}> Create-Menu</NavLink>
                <NavLink to='/orders' style={navLinkStyles}> Orders</NavLink>
                {/* {!loggedIn ?
                    (<NavLink to='/Login' style={navLinkStyles}> Login</NavLink>) :
                    <button className='logoutButton' onClick={signOut} >
                        <img src={power} />
                    </button>
                } */}
                <button onClick={toggleNavbar} className="sidebar-button">
                    <span className="material-symbols-outlined">Menu</span>
                </button>
                {!loggedIn ?
                        (<NavLink to='/Login' style={navLinkStyles}> Login</NavLink>) :
                        <button className='logoutButton' onClick={signOut} >
                            <img src={power} />
                        </button>
                    }
            </div>
            
        </div>
    )
}
