import axios from '../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import { refreshAccessToken } from '../features/auth/authSlice';

const useRefreshToken = () => {
    const dispatch = useDispatch();

    const refresh = async () => {
        const response = await axios.get('/auth/refresh', {
            withCredentials: true
        });
        console.log(response.data)
        dispatch(refreshAccessToken({
            roles: response.data.roles,
            accessToken: response.data.accessToken,
        }))
        return response.data.accessToken
    }
    return refresh
}

export default useRefreshToken
