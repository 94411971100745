import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { login } from '../features/auth/authSlice';
import '../styles/SignUp.css';

import BannerImage from "../assets/fastfood2.jpg";
import * as Components from '../components';
import { clearLoginMessages } from '../features/auth/authSlice';

export const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [inputs, setInputs] = useState({});
    const [emailSpan, setEmailSpan] = useState('');
    const [emailTick, setEmailTick] = useState(false);
    const [passwordSpan, setPasswordSpan] = useState('');
    const [passwordTick, setPasswordTick] = useState(false);
    const { loginSuccess, loginErrorMsg } = useSelector((state) => state.auth);


    const clearLoginErrorMessages = () => {
        dispatch(clearLoginMessages())
        setEmailTick(false)
        setPasswordTick(false)
    }

    useEffect(()=>{
        if (loginSuccess == true){
            navigate('/menu')
        }
    }, [loginSuccess])

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value
        setInputs((values) => ({ ...values, [name]: value }))
    }


    const formValidation = () => {
        var {email, password } = inputs;

        // validate email existance
        if (!email) {
            setEmailSpan(' ** Please fill the email  field')
            setEmailTick(false)
            return false;
        } else {
            setEmailSpan('')
        }

        //validate email
        if (
            email.charAt(email.length - 4) != '.' &&
            email.charAt(email.length - 3) != '.'
        ) {
            setEmailSpan(' ** Invalid Email')
            setEmailTick(false)
            return false
        } else {
            setEmailTick(true)
            setEmailSpan('')
        }

        // check password existance
        if (!password) {
            setPasswordSpan('** Please fill the password field')
            return false
        } else {
            setPasswordSpan('')
        }

        // validate password length
        if (password.length <= 5 || password.length > 20) {
            setPasswordSpan(' ** Passwords length must be between 5 and 20')
            return false
        } else {
            setPasswordTick(true)
            setPasswordSpan('')
        }

        return true;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // validate the form, then dispatch
        if (formValidation()) {
            dispatch(login(inputs))
            
        }
    }

    return (
        <div className='container' style={{ backgroundImage: `url(${BannerImage})` }} >
            <br />
            {loginErrorMsg == '' ? 
            (
                <div className='col-lg-5 m-auto d-block' >
                <form onSubmit={handleSubmit} className='bg-light' >
                    <div className='form-group' >
                        <label for='email'>Email</label>
                        <span className={`material-symbols-outlined ${emailTick ?  'check':'checkHide'}`} >check</span>
                        <input type='email' name='email' className='form-control' onChange={handleChange} />
                        <span id='emailid' className='text-danger'> {emailSpan} </span>
                    </div>
                    <div className='form-group' >
                        <label for='password'>Password</label>
                        <span className={`material-symbols-outlined ${passwordTick ?  'check':'checkHide'}`} >check</span>
                        <input type='password' name='password' className='form-control' onChange={handleChange} />
                        <span id='password' className='text-danger'> {passwordSpan} </span>
                    </div>
                    <div className='loginbuttons'>
                        <button type='submit' className='btn btn-primary' >Login</button>
                        <a className='text' > Don't have an account? </a>
                        <Link to="/signup">
                            <p className='btn btn-signup' > SignUp </p>
                        </Link>
                    </div>
                </form>
            </div>
            ): 
            <Components.Modal closeFunction= {clearLoginErrorMessages} modelType='loginErrorModal' /> 
            }
        </div>
    )
}
